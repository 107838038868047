import { getOneFromApi, initialFormState, InitialFormStateType, updateOneFromApi } from '../../utils/baseReducers'
import { OFFER_GET, OFFER_UPDATE, OFFER_UPDATE_PROVIOSON } from './offerActions'
import { ADD_OFFER_AFFILIATE_LINK_TO_SERVICE, REMOVE_OFFER_AFFILIATE_LINK_FROM_SERVICE, UPDATE_OFFER_AFFILIATE_LINK_TO_SERVICE } from './offerAffiliateLinkServiceActions'
import { ADD_DEBTORS_TO_OFFER, REMOVE_DEBTORS_FROM_OFFER } from './offerDebtorsActions'
import { ADD_FORMS_AGREEMENT_TO_OFFER, REMOVE_FORMS_AGREEMENT_FROM_OFFER, UPDATE_FORMS_AGREEMENT_TO_OFFER } from './offerFormsAgreementsActions'
import { ADD_PARAMETER_TO_OFFER, REMOVE_PARAMETER_FROM_OFFER, UPDATE_PARAMETER_TO_OFFER } from './offerParametersActions'
import { OfferActionsTypes, OfferAffiliateLinkService, OfferDebtor, OfferFormsAgreement, OfferParameter } from './types'

const offersReducer = (
  state: InitialFormStateType = initialFormState,
  action: OfferActionsTypes,
) => {
  switch (action.type) {
  case OFFER_GET: {
    return getOneFromApi(state, action.payload)
  }

  case OFFER_UPDATE: {
    return updateOneFromApi(state, action.payload)
  }

  case OFFER_UPDATE_PROVIOSON: {
    return {
      ...state,
      data: {
        ...state.data,
        commission: action.payload,
      },
    }
  }

  case ADD_PARAMETER_TO_OFFER: {
    const parameters = state.data.offersParameters || []
    const newData = {
      ...state.data,
      offersParameters: parameters.concat(
        {
          ...action.payload.parameter,
          parameter: action.payload.parameter,
        },
      ),
    }

    return {
      ...state,
      data: newData,
    }
  }

  case UPDATE_PARAMETER_TO_OFFER: {
    return {
      ...state,
      data: {
        ...state.data,
        offersParameters: state.data.offersParameters.map(
          (parameter: OfferParameter) =>
            parameter.parameterId === action.payload.parameter.parameterId
            && parameter.serviceId === action.payload.parameter.serviceId ? {
              ...parameter,
              parameter: action.payload.parameter,
              value: action.payload.parameter.value,
              description: action.payload.parameter.description || '',
            } : parameter,
        ),
      },
    }
  }

  case REMOVE_PARAMETER_FROM_OFFER: {
    return {
      ...state,
      data: {
        ...state.data,
        offersParameters: state.data.offersParameters.filter(
          (parameter: OfferParameter) => parameter.parameterId !== action.payload.parameterId
            || parameter.serviceId !== action.payload.serviceId,
        ),
      },
    }
  }

  case ADD_FORMS_AGREEMENT_TO_OFFER: {
    const formsAgreements = state.data.offersFormsAgreements || []

    return {
      ...state,
      data: {
        ...state.data,
        offersFormsAgreements: formsAgreements.concat(action.payload),
      },
    }
  }

  case UPDATE_FORMS_AGREEMENT_TO_OFFER: {
    return {
      ...state,
      data: {
        ...state.data,
        offersFormsAgreements: state.data.offersFormsAgreements.map(
          (formsAgreement: OfferFormsAgreement) =>
            formsAgreement.id === action.payload.id ? {
              ...formsAgreement,
              ...action.payload,
            } : formsAgreement,
        ),
      },
    }
  }

  case REMOVE_FORMS_AGREEMENT_FROM_OFFER: {
    return {
      ...state,
      data: {
        ...state.data,
        offersFormsAgreements: state.data.offersFormsAgreements.filter(
          (formsAgreement: OfferFormsAgreement) => formsAgreement.id !== action.payload,
        ),
      },
    }
  }

  case ADD_DEBTORS_TO_OFFER: {
    const parameters = state.data.offersDebtorsBases || []
    const newData = {
      ...state.data,
      offersDebtorsBases: parameters.concat({
        ...action.payload.debtor,
        debtorsBaseId: action.payload.debtor.id,
      }),
    }

    return {
      ...state,
      data: newData,
    }
  }

  case REMOVE_DEBTORS_FROM_OFFER: {
    return {
      ...state,
      data: {
        ...state.data,
        offersDebtorsBases: state.data.offersDebtorsBases.filter(
          (debtor: OfferDebtor) =>
            parseInt((debtor.debtorsBaseId as unknown) as string, 10) !==
            parseInt((action.payload.debtorId as unknown) as string, 10),
        ),
      },
    }
  }

  case ADD_OFFER_AFFILIATE_LINK_TO_SERVICE: {
    const offersAffiliateLinksServices =
      state.data.offersAffiliateLinksServices || []

    const newData = {
      ...state.data,
      offersAffiliateLinksServices: offersAffiliateLinksServices.concat(
        action.payload,
      ),
    }

    return {
      ...state,
      data: newData,
    }
  }

  case UPDATE_OFFER_AFFILIATE_LINK_TO_SERVICE: {
    return {
      ...state,
      data: {
        ...state.data,
        offersAffiliateLinksServices: state.data.offersAffiliateLinksServices.map(
          (offersAffiliateLinksServices: OfferAffiliateLinkService) =>
            offersAffiliateLinksServices.serviceId ===
            action.payload.serviceId
              ? action.payload
              : offersAffiliateLinksServices,
        ),
      },
    }
  }

  case REMOVE_OFFER_AFFILIATE_LINK_FROM_SERVICE: {
    return {
      ...state,
      data: {
        ...state.data,
        offersAffiliateLinksServices: state.data.offersAffiliateLinksServices.filter(
          (offersAffiliateLinksServices: OfferAffiliateLinkService) =>
            offersAffiliateLinksServices.serviceId !==
            action.payload.serviceId,
        ),
      },
    }
  }

  default: {
    return state
  }
  }
}

export default offersReducer
