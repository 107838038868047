import { isNull, omit, omitBy } from 'lodash'
import { Dispatch, AnyAction } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { Offer } from './types'

export const OFFER_GET = 'OFFER_GET'
export const OFFER_UPDATE = 'OFFER_UPDATE'
export const OFFER_UPDATE_PROVIOSON = 'OFFER_UPDATE_PROVIOSON'

export const getOffer = (offerId: Offer['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const getResponse = await makeRequest({
    axiosRequest: () =>
      axios.get(
        `/offers/${offerId}?relations=parameter-all,offersFormsAgreements-raw,debtorsBases,ranking,affiliateLinkService,product`
      ),
  })

  if (getResponse) {
    dispatch({
      type: OFFER_GET,
      payload: getResponse.data,
    })
  }
}

export const updateOffer = (offerId: Offer['id'], offer: Offer) => {
  const offerData = omit(omitBy(offer, isNull), [
    'offerRatings',
    'offerParameters',
    'tableData',
    'rate',
    'bank',
    'productType',
  ])

  return async (dispatch: Dispatch<AnyAction>) => {
    const updateResponse = await makeRequest({
      axiosRequest: () => axios.patch(`/offers/${offerId}`, offerData),
      successText: 'Offer updated',
    })

    if (updateResponse) {
      dispatch({
        type: OFFER_UPDATE,
        payload: offer,
      })
    }
  }
}
